<template>
<div style="display: flex;justify-content: center;align-items: center; height: 100%;">
    <h1>404 Not Found</h1>
</div>
</template>
<script>
export default {
}
</script>
<style scoped>
h1 {
    font-size: 72px;
    font-weight: lighter;
}
</style>