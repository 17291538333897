import Axios from "axios"

export default {
    getToken() {
        return localStorage.getItem("token");
    },
    getLoginFlag () {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return true;
        }
        return false;
    },
    getMyInfo() {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.getUserInfo( uid );
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    getUserInfo(uid) {
        return Axios.get("/api/v1/user/searchByUid", {
            headers: {
                "token": this.getToken()
            },
            params: {
                uid
            }
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    login(username, password) {
        return Axios.post("/api/v1/user/login", {
            uid: username,
            userPassword: password,
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    updateMyInfo(loginShell, phone, mail, cn) {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.updateUserInfo(uid, "", loginShell, mail, phone, cn, "0")
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    updateMyPassword(password) {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.updateUserInfo(uid, password, "", "", "", "", "0");
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    updateUserInfo(uid, userPassword, loginShell, mail, telephoneNumber, cn, isAdminUpdate) {
        return Axios.put("/api/v1/user/updateAUser", {
            uid,
            userPassword,
            loginShell,
            mail,
            telephoneNumber,
            cn,
            isAdminUpdate
        }, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    getMyPublickey() {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.getUserPublickey(uid);
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    getUserPublickey(uid) {
        return Axios.get("/api/v1/publicKey/searchByUid", {
            headers: {
                "token": this.getToken()
            },
            params: {
                uid
            }
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    addMyPublickey(title, publicKey) {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.addUserPublickey(uid, title, publicKey);
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    addUserPublickey(uid, title, publicKey) {
        return Axios.post("/api/v1/publicKey/addAKey", {
            uid,
            title,
            publicKey
        }, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        })
    },
    deleteMyPublickey(keyId) {
        let uid = localStorage.getItem("uid");
        if (uid) {
            return this.deleteUserPublickey(uid, keyId);
        } else {
            return Promise.resolve({code: -1, message: "未登录"});
        }
    },
    deleteUserPublickey(uid, keyId) {
        return Axios.delete("/api/v1/publicKey/deleteAKey", {
            params: {
                uid,
                keyId,
            },
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    getUserList() {
        return Axios.get("/api/v1/user/listAllUsersUid", {
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        })
    },
    addUser(data) {
        return Axios.post("/api/v1/user/createAUser", data, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    getGroupList() {
        return Axios.get("/api/v1/group/listAllGroup", {
            headers: {
                "token": this.getToken()
            },
        }).then(res => {
            return Promise.resolve(res.data);
        })
    },
    addGroup(cn) {
        return Axios.post("/api/v1/group/createAGroup", {}, {
            headers: {
                "token": this.getToken()
            },
            params: {
                cn
            }
        }).then(res => {
            return Promise.resolve(res.data);
        })
    },
    getGroupInfo(cn) {
        return Axios.get("/api/v1/group/searchByCn", {
            params: {
                cn
            },
            headers: {
                "token": this.getToken()
            }
        }).then(res => {
            return Promise.resolve(res.data);
        });
    },
    addGroupMember(uid, cn) {
        return Axios.put("/api/v1/group/updateAGroup", {
            uid,
            cn,
            updateType: 1,
        }, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => Promise.resolve(res.data));
    },
    removeGroupMember(uid, cn) {
        return Axios.put("/api/v1/group/updateAGroup", {
            uid,
            cn,
            updateType: 3,
        }, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => Promise.resolve(res.data));
    },
    logout() {
        return Axios.post("/api/v1/user/logout", {}, {
            headers: {
                "token": this.getToken()
            },
        }).then(res => Promise.resolve(res.data));
    }
}