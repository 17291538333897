<template>
  <div id="app">
    <el-container style="height: 100%">
      <el-header class="header" height="50px">
        <p style="margin-top: 10px">THUNLP 账号管理平台</p>
      </el-header>
      <el-container style="height: calc(100% - 50px)">
        <el-aside class="aside" v-if="logined">
          <el-menu style="min-height: 100%" router>
            <el-menu-item index="/info">
              <i class="el-icon-s-custom"></i> 个人信息 ({{
                uid
              }})</el-menu-item
            >
            <el-submenu index="1">
              <template slot="title"
                ><i class="el-icon-edit"></i>修改信息</template
              >
              <el-menu-item index="/update/password">修改密码</el-menu-item>
              <el-menu-item index="/update/info">修改个人信息</el-menu-item>
              <el-menu-item index="/update/pubkey">修改公钥</el-menu-item>
            </el-submenu>

            <el-submenu index="2" v-if="is_admin">
              <template slot="title"
                ><i class="el-icon-setting"></i>管理</template
              >
              <el-menu-item index="/manage/user">用户管理</el-menu-item>
              <el-menu-item index="/manage/group">组管理</el-menu-item>
            </el-submenu>
            <el-menu-item index="/logout"><i class="el-icon-switch-button"></i>退出登陆</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main v-loading="loading">
          <router-view v-if="!loading"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import api from "./api"
export default {
    name: "app",
    data: () => ({
        logined: true,
        uid: "zgy",
        is_admin: true,
        loading: true
    }),
    mounted() {
        let token = localStorage.getItem("token");
        if (token) {
            this.uid = localStorage.getItem("uid");
            api.getMyInfo().then(res => {
                if (res.code == 0) {
                    this.uid = res.data.uid;
                    this.logined = true;
                    this.is_admin = (res.data.userType == 1);
                } else {
                    this.logined = false;
                    this.uid = null;
                    localStorage.removeItem("token");
                    localStorage.removeItem("uid");
                    this.gotoLogin();
                }
                this.loading = false;
            }).catch(err => {
                this.$message.error(err.toString());
                console.error(err);
                this.loading = false;
            });
        } else {
            this.logined = false;
            this.uid = null;
            this.gotoLogin();
            this.loading = false;
        }
    },
    methods: {
      gotoLogin() {
        this.$router.push("/login");
      }
    }
};
</script>
<style>
body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}
#app {
    height: 100%;
    font-size: 20px;
}
* {
    box-sizing: border-box;
}
</style>
<style scoped>
.header {
    background-color: #10464e;
    color: #ffffff;
    font-weight: bold;
}
.aside {
    height: 100%;
}
</style>
