import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from '@/views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/info"
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "info" */ '@/views/Info.vue')
  },
  {
    path: '/update/password',
    name: 'UpdatePassword',
    component: () => import(/* webpackChunkName: "update_password" */ '@/views/update/Password.vue')
  },
  {
    path: '/update/info',
    name: 'UpdateInfo',
    component: () => import(/* webpackChunkName: "update_info" */ '@/views/update/Info.vue')
  },
  {
    path: '/update/pubkey',
    name: 'UpdatePubkey',
    component: () => import(/* webpackChunkName: "update_pubkey" */ '@/views/update/PublicKey.vue')
  },
  {
    path: '/manage/user',
    name: 'ManageUser',
    component: () => import(/* webpackChunkName: "manage_user" */ '@/views/manage/User.vue')
  },
  {
    path: '/manage/group',
    name: 'ManageGroup',
    component: () => import(/* webpackChunkName: "manage_group" */ '@/views/manage/Group.vue')
  },
  {
    path: '/manage/group_user',
    name: 'ManageGroupUser',
    component: () => import(/* webpackChunkName: "manage_group" */ '@/views/manage/group_user.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue')
  },
  {
    path: "*",
    name: "404",
    component: Page404
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
